<template>
  <v-card flat class="product-view">
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card-text class="pt-0 pb-1 px-0">
      <v-row class="ma-0">
        <v-col cols="6" class="py-1 px-2">{{ orderModel.number }} - {{ orderModel.warehouse_name }}</v-col>
        <v-col cols="6" class="px-2 py-1 text-right" v-if="orderModel.orderdate">{{ $t('message.order.delivery') }}: {{ orderModel.orderdate | dateFormat }}</v-col>
      </v-row>
      <!-- <v-row class="ma-0">
        <v-col cols="12" class="pb-0 pt-0">{{ orderModel.warehouse_name }}</v-col>
      </v-row> -->
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0 pl-3">{{ orderModel.customer_name || '' }}</v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="highlight pt-0 pb-0 pl-1">
      <v-row class="ma-0">
        <v-col cols="6" class="pa-0 pl-3 font-weight-bold">{{ $t('message.order.products') }}:</v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right font-weight-bold">{{ $t('message.order.ordered') }}</v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right font-weight-bold">{{ $t('message.order.received') }}</v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right font-weight-bold">{{ $t('message.ccsheet.unit') }}</v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-divider class="pa-0"></v-divider>
        </v-col>
      </v-row>
      <!-- Load list of products -->
      <v-row class="ma-0" v-for="(product, index) in listOfProducts" :key="index">
        <v-col cols="6" class="pa-0 pl-3" :class="{ 'pb-1': (index === listOfProducts.length - 1)}">
          <v-row class="ma-0">
            <v-col cols="6" class="pa-0 text-start text-truncate">{{ product.productnumber }}</v-col>
            <v-col cols="6" class="pa-0 ml-n3 text-truncate" :title="product.productname">{{ product.productname }}</v-col>
          </v-row>
        </v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right">{{ product.orderedqty }}</v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right">
          {{ product.receivedqty }}
        </v-col>
        <v-col cols="2" class="pa-0 pl-3 text-right">
          <span class="caption">{{ product.unitname }}</span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small class="mt-2" color="primary" @click="pickOrderHandler">{{ $t('message.order.pick') }}</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import { hostAppApi } from '@/plugins/axios_settings'
export default {
  data () {
    return {
      loading: false,
      orderId: 0,
      listOfProducts: [],
      orderModel: {}
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  mounted () {
    this.loading = true
    this.orderId = this.$route.params.order_id
    this.getOrderProducts()
    // this.getOrderInfo()
  },
  methods: {
    getOrderInfo () {
      const model = {
        expand: [{
          select: ['name'],
          model: 'Warehouse',
          on_field: 'id',
          on_parent_field: 'warehouseid'
        }],
        filter: `id eq '${this.orderId}'`
      }
      this.$api.execute('post', 'warehouseorders/query', model)
        .then((response) => {
          if (response.data) {
            this.orderModel = response.data[0]
          }
        })
    },
    getOrderProducts () {
      hostAppApi.get(`${this.getHostRefApi}warehouseorders/get_products_by_order/${this.orderId}?user_id=${this.userId}`)
        .then((response) => {
          if (response.data) {
            const { order, products } = response.data
            this.orderModel = order
            const list = products
            list.map((x) => {
              x.receivedqty = this.$formatter.absoluteNumber(x.receivedqty)
              x.orderedqty = this.$formatter.absoluteNumber(x.orderedqty)
              return x
            })
            this.listOfProducts = list
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    pickOrderHandler () {
      this.$router.push(`/adjustment_orders/${this.orderId}/adjust`)
    }
  }
}
</script>
